import React from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/Layout'
import {
  Container,
} from 'reactstrap';

const CMSPage = ({data}) => {
  const livePages = data.allContentfulFacebookLive.edges.map(({node}) => ({ ...node }))
  return (
    <Layout
        section="cms-white-paper"
        title={`CMS`}
      >
      <Container className="py-5">
        <h1>CMS</h1>
        <a href="/cms/white-paper">White paper Docs</a>
        <h2>Live pages</h2>
        <ul>
          {livePages.map(({title, slug}) => (
            <li>
              <a href={`/live/${slug}`}>{title}</a>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  )
}

export default CMSPage

export const pageQuery = graphql`
  query LiveQuery {
    allContentfulFacebookLive {
      edges {
        node {
	        ...ContentfulFacebookLiveFragment
  	    }
      }
    }
  }
`